<template lang="html">
  <img
    class="form__background-picture form__background-picture--center"
    src="@/assets/images/building2.png"
    alt="Building"
  />
  <div class="form form-checkout">
    <!--    <div class="form__heading">-->
    <!--      <Heading text="Checkout" :hType="2" textAlign="center" />-->
    <!--    </div>-->
    <div class="form__checkout-heading">
      <!--      <Heading text="Zahlung war erfolgreich" :hType="1" textAlign="center" />-->
      <Heading
        v-if="globalSettings && globalSettings.checkout_heading"
        :text="globalSettings.checkout_heading"
        :hType="1"
        textAlign="center"
      />
    </div>
    <div
      v-if="globalSettings && globalSettings.checkout_description"
      class="form__description"
      v-html="globalSettings.checkout_description"
    ></div>
    <!--    <div class="form__checkout-icon">-->
    <!--      <svg-->
    <!--        width="98"-->
    <!--        height="98"-->
    <!--        viewBox="0 0 98 98"-->
    <!--        fill="none"-->
    <!--        xmlns="http://www.w3.org/2000/svg"-->
    <!--      >-->
    <!--        <path-->
    <!--          d="M24 54.0961L34.2083 68.5836C34.5956 69.162 35.1153 69.6395 35.7244 69.9765C36.3334 70.3135 37.0141 70.5001 37.7099 70.5209C38.4056 70.5417 39.0963 70.396 39.7243 70.096C40.3524 69.796 40.8998 69.3503 41.3208 68.7961L74 27.4502"-->
    <!--          stroke="#219653"-->
    <!--          stroke-width="4"-->
    <!--          stroke-linecap="round"-->
    <!--          stroke-linejoin="round"-->
    <!--        />-->
    <!--        <path-->
    <!--          d="M2.125 48.9958C2.125 61.4279 7.0636 73.3507 15.8544 82.1415C24.6451 90.9322 36.568 95.8708 49 95.8708C61.432 95.8708 73.3549 90.9322 82.1456 82.1415C90.9364 73.3507 95.875 61.4279 95.875 48.9958C95.875 36.5638 90.9364 24.641 82.1456 15.8502C73.3549 7.05944 61.432 2.12084 49 2.12084C36.568 2.12084 24.6451 7.05944 15.8544 15.8502C7.0636 24.641 2.125 36.5638 2.125 48.9958V48.9958Z"-->
    <!--          stroke="#219653"-->
    <!--          stroke-width="4"-->
    <!--          stroke-linecap="round"-->
    <!--          stroke-linejoin="round"-->
    <!--        />-->
    <!--      </svg>-->
    <!--    </div>-->

    <div v-if="globalSettings" class="form__checkout-image">
      <div
        class="new-candle"
        v-if="
          `/${$store.state.global.slugs.candles}` === $route.path &&
          globalSettings.checkout_image_donations
        "
      >
        <Candle :info="{ status: 'new' }" />
      </div>
      <img
        v-else-if="
          `/${$store.state.global.slugs.donationShop}` === $route.path &&
          globalSettings.checkout_image_donations
        "
        :src="globalSettings.checkout_image_donations.sizes.medium_large"
        alt="Checkout"
      />
      <img
        v-else-if="
          `/${$store.state.global.slugs.organ}` === $route.path &&
          $store.state.organ.image
        "
        :src="$store.state.organ.image"
        alt="Checkout"
      />
      <img
        v-else-if="globalSettings.checkout_image_stones"
        :src="globalSettings.checkout_image_stones.sizes.medium_large"
        alt="Checkout"
      />
    </div>

    <div class="form__buttons form__buttons--checkout">
      <div class="form__button">
        <Button @click="checkoutButton">
          <span v-if="globalSettings">
            <template v-if="buttonLabel">{{ buttonLabel }}</template>
            <template v-else>
              {{ globalSettings['button_names']['go_to_your_donation'] }}
            </template>
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading.vue';
import Button from '@/components/Button/Button.vue';
import { replacePlaceholders } from '@/utils';
import Candle from '@/components/Candle/Candle.vue';

export default {
  name: 'FormCheckout',
  components: {
    Candle,
    Heading,
    Button,
  },
  props: {
    buttonLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      description: '',
      descriptionFromServer:
        'Sobald Ihre Zahlung bearbeitet wurde, erhalten Sie eine Bestätigungsmail als Spendennachweis.',
    };
  },
  emits: ['checkoutNextStep'],
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  methods: {
    checkoutButton() {
      this.$emit('checkoutNextStep');
    },
  },
  mounted() {
    this.description = replacePlaceholders(this.descriptionFromServer);
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
