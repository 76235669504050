<template lang="html">
  <div class="candle">
    <div
      v-if="info.status === 'create'"
      class="candle__container candle__container--burned candle__container--create"
      @click.passive="showCreateCandleForm"
    >
      <CandleTemplate />
      <div class="candle__button">
        <Button styleButton="radius" backgroundColor="#C99D66">
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['light_button'] }}</span
          >
        </Button>
      </div>
    </div>
    <div
      v-if="info.status === 'new'"
      class="candle__container candle__container--lit"
    >
      <CandleTemplate :flame="true" type="new" />
    </div>
    <div
      v-else-if="info.status === 'burned'"
      class="candle__container candle__container--burned"
    >
      <img
        class="candle__image"
        src="@/assets/images/burned-candle.png"
        alt="Lit Candles"
      />
      <div class="candle__button">
        <Button styleButton="radius" backgroundColor="#C99D66">
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['light_button'] }}</span
          >
        </Button>
      </div>
    </div>
    <div
      v-else-if="info.status === 'lit'"
      class="candle__container candle__container--lit"
      @click.passive="clickCandleButton"
    >
      <CandleTemplate :flame="true" />
      <div class="candle__button">
        <!--        <div class="candle__info">-->
        <!--          <div-->
        <!--            class="candle__info-title"-->
        <!--            v-if="info.last_name && globalSettings.labels_and_titles"-->
        <!--          >-->
        <!--            {{ globalSettings.labels_and_titles.candle_title_in_carousel }}-->
        <!--            {{ info.candle_from }}-->
        <!--          </div>-->
        <!--          <div-->
        <!--            class="candle__info-message"-->
        <!--            v-if="info.message"-->
        <!--            v-html="info.message"-->
        <!--          ></div>-->
        <!--        </div>-->
        <Button styleButton="radius" backgroundColor="#0062AE">
          <span v-if="globalSettings">
            <!--            {{ globalSettings['button_names']['read_button'] }}-->
            {{ globalSettings.labels_and_titles.candle_title_in_carousel }}
            {{ info.candle_from }}
            <span
              ref="icon"
              data-template="candle-info"
              class="candle__button-icon"
              @click.passive.stop="showPreview"
            >
              <img src="@/assets/images/Letter.svg" />
            </span>
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import CandleTemplate from '@/components/Candle/CandleTemplate';

export default {
  name: 'Candles',
  components: {
    CandleTemplate,
    Button,
  },
  props: {
    info: {
      type: Object,
      required: false,
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    showCreateCandleForm() {
      this.$store.commit('donationCandles/setCreateCandleFormIsShow', true);
    },
    clickCandleButton() {
      this.$router.push({
        name: 'single-page',
        params: { slug: this.$route.params.slug, id: this.info.ID },
      });
    },
    showPreview() {
      const iconCoords = this.$refs.icon.getBoundingClientRect();
      this.$store.commit('global/setCandlePreview', {
        info: this.info,
        position: iconCoords,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
.candle__container--create {
  display: none;
}
</style>
