<template lang="html">
  <div
    class="candle-template"
    :class="[
      { 'candle-template--new': type === 'new' },
      `candle-template--${randomIntFromInterval(1, 10)}`,
    ]"
  >
    <div class="candle-template__top">
      <img
        v-if="flame"
        class="candle-template__light"
        src="@/assets/images/candle-light.png"
        alt="Candle Light"
      />
      <div class="candle-template__flame" v-if="flame">
        <div class="candle-template__flame-shadows"></div>
        <div class="candle-template__flame-top"></div>
        <div class="candle-template__flame-middle"></div>
        <div class="candle-template__flame-bottom"></div>
      </div>
    </div>

    <img
      class="candle-template__image"
      :src="setImageSrc()"
      alt="Lit Candles"
    />
  </div>
</template>

<script>
export default {
  name: 'CandleTemplate',
  props: {
    flame: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  methods: {
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    setImageSrc() {
      // const randNumber = this.getRandomArbitrary(1, 4);
      // return require('@/assets/images/candles/candle-' + randNumber + '.png');
      const randNumber = this.getRandomArbitrary(1, 2);
      const imageName = this.flame
        ? `candle-burning-${randNumber}`
        : `candle-${randNumber}`;
      return require(`@/assets/images/new-candles/${imageName}.png`);
    },
    getRandomArbitrary(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
